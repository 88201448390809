<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent
          class="py-8"
          title="1.6 Article Slow Cook-off Test"
          size="large"
        />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="1.6 Article Slow Cook-off Test Description"
              size="medium"
            />
            <p class="p-light">
              The article is slowly heated to 365°C and the resulting reaction
              evaluated.
            </p>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The 1.6 Article Slow Cook-off Test is completed to determine if
              the candidate for a 1.6 classification explodes or reacts
              violently when slowly heated to ignition. Detonation, explosion,
              or fragments thrown more than 15 m or other reaction more severe
              than burning (as described in Appendix 8 of the UN MTC) indicate
              the article is not a candidate as a 1.6 Article.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objective</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>Article configuration</td>
                    <td>Simulate a slow cook-off scenario</td>
                    <td></td>
                    <td>
                      Each article is heated in an oven. Secondary reactions
                      including exudate or flammable gasses igniting due to
                      contact with the oven or heating device invalidate the
                      test.
                    </td>
                  </tr>
                  <tr>
                    <td>Oven</td>
                    <td>
                      Exposes the substance to a slowly increasing temperature
                      until it ignites.
                    </td>
                    <td></td>
                    <td>
                      The oven temperature is increased by 3.3°C per hour to
                      365°C. The temperature of the oven and of the pipe is
                      continuously recorded.
                    </td>
                  </tr>
                  <tr>
                    <td>Number of trials</td>
                    <td>Ensure proper classification</td>
                    <td></td>
                    <td>Two trials are completed.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr>
                    <td>Detonation</td>
                    <td>
                      Immediate consumption of the entire substance in the
                      article
                    </td>
                    <td>
                      Primary assessment is rapid plastic deformation of the
                      metal casing in contact with the explosive substance with
                      accompanied shock wave corresponding to mass and type of
                      the substance. Secondary assessment includes damage to
                      witness plate(s) and corresponding blast effects such as
                      craters or other damage to surroundings. NOT A 1.6 ARTICLE
                    </td>
                  </tr>
                  <tr>
                    <td>Partial detonation</td>
                    <td>
                      Immediate consumption of a portion of the entire substance
                      in the article
                    </td>
                    <td>
                      Primary assessment is rapid plastic deformation of a
                      portion of the metal casing in contact with the explosive
                      substance with accompanied shock wave corresponding to
                      less than that expected for the mass and type of the
                      substance. Secondary assessment includes damage to witness
                      plate(s) and corresponding blast effects such as craters
                      or other damage to surroundings. NOT A 1.6 ARTICLE
                    </td>
                  </tr>
                  <tr>
                    <td>Explosion</td>
                    <td>Rapid consumption of the substance in the article</td>
                    <td>
                      Primary assessment is fracturing of the metal casing
                      (larger pieces than seen in a detonation) in contact with
                      the explosive substance. Secondary assessment includes a
                      pressure wave of a smaller magnitude and longer duration
                      than seen in a detonation event with damage to witness
                      plate(s) and long-distance scattering of burning or
                      unburned pieces of the substance or article. NOT A 1.6
                      ARTICLE
                    </td>
                  </tr>
                  <tr>
                    <td>Deflagration</td>
                    <td>Combustion of the substance in the article</td>
                    <td>
                      Primary assessment is combustion (longer reaction time
                      than an explosion) of some or all of the substance with
                      rupture of the article into a few large pieces with at
                      least one piece traveling beyond 15m with energy &gt;20J.
                      Secondary assessment may include a pressure wave of a
                      small magnitude and long duration (less than seen in an
                      explosion event). NOT A 1.6 ARTICLE
                    </td>
                  </tr>
                  <tr>
                    <td>Burn</td>
                    <td>Burning of the substance in the article</td>
                    <td>
                      Primary assessment is a low pressure burn of some or all
                      of the substance in the article with likely associated
                      rupture of the article however no item travels beyond 15m
                      and does NOT have an associated energy &gt;20J. CANDIDATE
                      AS A 1.6 ARTICLE
                    </td>
                  </tr>
                  <tr>
                    <td>No Reaction</td>
                    <td>Substance in the article does not sustain burning</td>
                    <td>
                      Primary assessment is no sustained burning of the article
                      and no fragmentation of the article. CANDIDATE AS A 1.6
                      ARTICLE
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <SideBar class="w-full sm:w-1/5" />
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
